import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloClient, ApolloLink, ApolloProvider, from, HttpLink, InMemoryCache } from '@apollo/client';
import AuthProvider from 'views/auth/base/AuthProvider';
import ModalProvider from 'modules/modal/ModalProvider';
import Main from './Main';
const getToken = () => {
  // TODO get token from authProvider
  const token = sessionStorage.getItem('token');

  return token;
  // TODO check what is Bearer
  // return token ? `Bearer ${token}` : '';
};

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: getToken(),
    },
  });

  return forward(operation);
});

const development = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

const httpLink6 = new HttpLink({
  uri: development
    ? 'http://localhost:5000/graphql'
    : 'https://viper-production-server.herokuapp.com/graphql',
});
const httpLink = new HttpLink({
  uri: 'https://viper.todo.doar.systems/graphql',
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    freezeResults: true,
  }),
  link: from([authMiddleware, httpLink]),
});

// Set 'secret' in sessionStorage to allow special admin commands
if (window.location.hash === '#secret') {
  sessionStorage.setItem('secret', 'true');
}
// Listen for hash changes
window.addEventListener('hashchange', () => {
  location.reload();
});


const App = () => (
  <ApolloProvider client={client}>
    <AuthProvider>
      <ModalProvider>
        <Router>
          <Main />
        </Router>
      </ModalProvider>
    </AuthProvider>
  </ApolloProvider>
);

export default App;
